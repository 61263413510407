/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Jiyeon Heo",
  title: "Hi, I'm Jiyeon",
  subTitle: emoji(
    "Passionate full-stack software developer with a diverse background in building web and mobile applications using a variety of programming languages, libraries, and frameworks."
  ),
  resumeLink: require("./assets/Jiyeon_Heo_v2.pdf"), // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/JIYEON-H",
  linkedin: "https://www.linkedin.com/in/jiyeon-heo/",
  gmail: "jiyeon.heo0906@gmail.com",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Who am I",
  subTitle: "Full stack developer who really enjoys learning and exploring new things tech stack",
  skills: [
    emoji(
      "⚡ Passionate about software development and enjoys learning new skills, and applying them to real-world projects"
    ),
    emoji("⚡ Enjoys solving problems through coding and always strives to enhance teamwork and collaboration"),
    emoji(
      "⚡ Pays attention to details and emphasizes efficiency and readability when writing code, aiming for seamless integration and user satisfaction"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "C#",
      fontAwesomeClassname: "fab fa-microsoft"
    },
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "the Southern Alberta Institute of Technology",
      logo: require("./assets/images/sait.png"),
      subHeader: "Diploma in Information Technology - Software Development",
      duration: "January 2023 - Present",
      desc: "GPA: 3.98/4.00",
      descBullets: [
        "SAIT Student Code Club - SSC",
        "Jason Lang Scholarship — (Dec 2023)"
      ]
    },
    {
      schoolName: "EZEN IT Institute",
      logo: require("./assets/images/ezen.png"),
      subHeader: "Certificate in Java Web & App Developer",
      duration: "Jul 2021 - Dec 2021",
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer Intern",
      company: "POSY Inc.",
      companylogo: require("./assets/images/PosyLogo.png"),
      date: "May 2024 – Present",
      desc: "HCM & ERP",
      descBullets: [
        "Designed and implemented ETL pipelines to process client data with 98% accuracy, reducing data processing time by 70%",
        "Developed and maintained Spring Boot-based RESTful APIs for HCM systems, ensuring scalability and robust performance",
        "Implemented Role-Based Access Control (RBAC) to enhance security and compliance, enabling role-specific access permissions",
        "Ensured data consistency and reliability in a financial system using Firestore transactions and Google Cloud Functions",
        "Collaborated with cross-functional teams to align technical solutions with client requirements"
      ]
    },
    {
      role: "Full-stack Developer",
      company: "Artistry",
      companylogo: require("./assets/images/artistryLogo.png"),
      date: "Sep 2023 – Present",
      desc: "Volunteer work for local artists",
      descBullets: [
        "Optimized PostgreSQL database architecture for high performance and scalability",
        "Enhanced security by implementing OAuth 2.0 with Django, safeguarding sensitive user data",
        "Delivered key frontend features with React and TypeScript, improving user engagement"
      ]
    },
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "Some projects I have worked on",
  projects: [
    {
      projectName: "Care INC Audiology project",
      projectDesc: "An intuitive Inventory Management System designed to replace manual tracking with automation. It simplifies stock management, package assignments, and ordering processes, ensuring efficiency and accuracy for audiologists.",
      footerLink: [
        {
          name: "Visit Github (Frontend)",
          url: "https://github.com/Reann-Shepard/care-inc-IMS-frontend"
        },
        {
          name: "Visit Github (Backend)",
          url: "https://github.com/Reann-Shepard/care-inc-IMS-backend"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      // image: require("./assets/images/saayaHealthLogo.webp"),
      projectName: "Itinerary App",
      projectDesc: "Plan your journey. Our itinerary App is designed to optimize your travel experience. This app helps users easily plan and manage their travel itineraries. Travelers can create itineraries including destinations, itineraries, activities, etc., and view the locations of their travel destinations.",
      footerLink: [
        {
          name: "Visit Github",
          url: "https://github.com/Laurenchansj/WebDancers"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      // image: require("./assets/images/nextuLogo.webp"),
      projectName: "Expense Tracker",
      projectDesc: "Set budgets and track your spending. Our Expense Tracker App is designed to help you manage your finances. This app helps users easily track their expenses and manage their budgets. Users can create budgets, track expenses, and view their spending habits.",
      footerLink: [
        {
          name: "Visit Github",
          url: "https://github.com/HarryLee87/MoneyDancers"
        }
      ]
    },
    {
      // image: require("./assets/images/saayaHealthLogo.webp"),
      projectName: "Word Tracker",
      projectDesc: "This word tracking program utilizes a binary search tree data structure to store all the words from text files along with their file names, line numbers, and frequency. It organizes and displays the words in alphabetical order.",
      footerLink: [
        {
          name: "Visit GitHub",
          url: "https://github.com/JIYEON-H/WordTracker"
        }
      ]
    },
    {
      // image: require("./assets/images/saayaHealthLogo.webp"),
      projectName: "Library Management",
      projectDesc: "Manage your library. Our Library Management App is designed to help you manage your library. This app helps users easily manage their library. Users can add books, manage book loans, and view their library inventory.",
      footerLink: [
        {
          name: "Visit GitHub",
          url: "https://github.com/SarahNT1/Library_Management"
        }
        //  you can add extra buttons here.
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: ""
        },
        {
          name: "Award Letter",
          url: ""
        },
        {
          name: "Google Code-in Blog",
          url: ""
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: ""
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: ""
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "Coming soon...",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "",
      title: "Coming Soon",
      description:
        "Coming soon..."
    },
    {
      url: "",
      title: "Coming Soon",
      description:
        "Coming Soon"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "",
  email_address: "jiyeon.heo0906@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
